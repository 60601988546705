<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('fetchingUserError') }}
      </h4>
      <div class="alert-body">
        {{ $t('noUserFound') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          {{ $t('userList') }}
        </b-link>
        {{ $t('forOtherUsers') }}
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref } from '@vue/composition-api';
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue';
import UserViewUserInfoCard from './UserViewUserInfoCard.vue';
import UserViewUserPlanCard from './UserViewUserPlanCard.vue';
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue';
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
  },
  setup() {
    const userData = ref(null);

    const { id } = router.currentRoute.params;
    store.dispatch('users/fetchUserById', id);
    userData.value = store.state.users.currentUser;
    return {
      userData,
    };
  },
};
</script>

<style>

</style>
